import React from "react";

const RedirectPage: React.FC = () => {
	const queryString = window.location.search;
	const baseUrl = window.location.origin;
	const urlParams = new URLSearchParams(queryString);
	const paymentPortalLinkId = urlParams.get("payment_portal_link_id");
	const customerEmail = urlParams.get("customer_email");
	const sFlag = urlParams.get("s_flag");
	const successParam = sFlag ? `&success=true` : "";

	if (paymentPortalLinkId && customerEmail && successParam) {
		window.location.href = `${baseUrl}/${paymentPortalLinkId}?customer_email=${customerEmail}${successParam}`;
	} else if (paymentPortalLinkId) {
		window.location.href = `${baseUrl}/${paymentPortalLinkId}`;
	} else {
		window.location.href = baseUrl;
	}

	return (
		<div>
			<p>Redirecting to Payment Portal...</p>
		</div>
	);
};

export default RedirectPage;
