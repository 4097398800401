import React from "react";
import { LDProvider } from "launchdarkly-react-client-sdk";

import { ENV_VARS } from "@const/env";

export type FlagProviderProps = {
	children: React.ReactNode;
};

export const FlagProvider = ({ children }: FlagProviderProps) => {
	return (
		<LDProvider
			clientSideID={ENV_VARS.LAUNCH_DARKLY_CLIENT_ID}
			flags={{
				"enable-billing-pay-portal": true,
				"enable-billing-route-to-new-payment-portal": true,
				"billing-slim-cd-enabled-companies": "",
			}}
		>
			{children}
		</LDProvider>
	);
};
