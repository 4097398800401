import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

export const queryClient = new QueryClient({
	defaultOptions: {
		mutations: {
			retry: false,
		},
		queries: {
			retry: 2,
			staleTime: 5 * 60 * 1000,
		},
	},
});

export type ReactQueryProviderProps = {
	children: React.ReactNode;
};

export const ReactQueryProvider: React.FC<ReactQueryProviderProps> = ({ children }) => {
	return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default ReactQueryProvider;
