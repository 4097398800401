import React, { useState, useCallback, createContext } from "react";

import { EuiGlobalToastList, EuiGlobalToastListToast, htmlIdGenerator } from "@equipmentshare/ds2";

type ShowToastCallback = (toast: Omit<EuiGlobalToastListToast, "id">) => void;
type RemoveToastCallback = (toast: EuiGlobalToastListToast) => void;
type ContextType = {
	showToast: ShowToastCallback;
};

const defaultContext = {
	showToast: () => {},
};
const ToastContext = createContext<ContextType>(defaultContext);

export const useToastMessages = () => React.useContext(ToastContext);

export type ToastProviderProps = {
	children: React.ReactNode;
};

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
	const [toasts, setToasts] = useState<EuiGlobalToastListToast[]>([]);

	const showToast = useCallback<ShowToastCallback>(
		(toastToShow) => {
			setToasts([...toasts, { ...toastToShow, id: htmlIdGenerator()() }]);
		},
		[toasts],
	);

	const removeToast = useCallback<RemoveToastCallback>(
		(toastToRemove) => {
			setToasts(toasts.filter((toast) => toast.id !== toastToRemove.id));
		},
		[toasts],
	);

	return (
		<ToastContext.Provider value={{ showToast }}>
			{children}
			<EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
		</ToastContext.Provider>
	);
};
