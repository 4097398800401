import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { LoadingOverlay } from "@components/loading-overlay/loading-overlay";
import { useFlags } from "launchdarkly-react-client-sdk";
import RedirectPage from "@pages/redirect-page/redirect-page";

const InvoiceListPage = React.lazy(() => import("@pages/invoice-list/invoice-list-page"));
const ErrorPage = React.lazy(() => import("@pages/error-page/error-page"));

export const Router = () => {
	const { enableBillingPayPortal } = useFlags();

	const routes = (
		<Routes>
			<Route path="/:portalId" element={<InvoiceListPage />} />
			<Route path="/redirect" element={<RedirectPage />} />
			<Route path="/" element={<ErrorPage />} />
		</Routes>
	);

	return (
		<BrowserRouter>
			<Suspense fallback={<LoadingOverlay />}>
				{enableBillingPayPortal ? (
					routes
				) : (
					<Routes>
						<Route path="*" element={<ErrorPage />} />
					</Routes>
				)}
			</Suspense>
		</BrowserRouter>
	);
};

export default Router;
