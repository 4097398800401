import React, { createContext } from "react";
import * as E from "fp-ts/Either";

import { BillingServiceClient } from "@equipmentshare/billing-service-client";
import { newRestBillingServiceClient } from "@equipmentshare/billing-service-client/rest";

import { ENV_VARS } from "@const/env";

const BillingServiceClientContext = createContext<BillingServiceClient | null>(null);

export const useBillingServiceClient = () => {
	const client = React.useContext(BillingServiceClientContext);

	if (!client) {
		throw Error("Failed to connect to billing service client");
	}

	return client;
};

export type BillingServiceClientProviderProps = {
	children: React.ReactNode;
};

export const BillingServiceClientProvider: React.FC<BillingServiceClientProviderProps> = ({ children }) => {
	// Token will never be a 'real' token since there is no log in and thus no OAuth or identity to use for a token.
	// The only API calls made are to public endpoints so the token should not be needed. It is only added here because
	// the client requires something be provided.
	const tokenProvider = async () => E.of(`Bearer token`);
	const billingServiceClient = newRestBillingServiceClient(tokenProvider, `${ENV_VARS.API_URL}/billing-service`);

	return (
		<BillingServiceClientContext.Provider value={billingServiceClient}>{children}</BillingServiceClientContext.Provider>
	);
};
