import React from "react";

import { EuiProvider, THEME_TOKENS } from "@equipmentshare/ds2";

import { ReactQueryProvider } from "@providers/react-query-provider";
import { FlagProvider } from "@providers/flag-provider";
import { BillingServiceClientProvider } from "@providers/billing-service-client-provider";
import { ToastProvider } from "./providers/toast-provider";
import { Router } from "./router";

export const App: React.FC = () => {
	return (
		<EuiProvider modify={THEME_TOKENS}>
			<ToastProvider>
				<ReactQueryProvider>
					<FlagProvider>
						<BillingServiceClientProvider>
							<Router />
						</BillingServiceClientProvider>
					</FlagProvider>
				</ReactQueryProvider>
			</ToastProvider>
		</EuiProvider>
	);
};

export default App;
