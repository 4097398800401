import React from "react";
import { EuiOverlayMask, EuiLoadingSpinner } from "@equipmentshare/ds2";

export type LoadingOverlayProps = { className?: string };

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ className }) => {
	return (
		<EuiOverlayMask className={className} headerZindexLocation="above">
			<EuiLoadingSpinner size="xxl" />
		</EuiOverlayMask>
	);
};

export default LoadingOverlay;
